<template>
  <div v-if="widget.outer == 'tpl/heading'">
    <page-builder-heading :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Blog/article_list'">
    <page-builder-article-list :templatevar="widget.templatevar" :group="props.urlParts.item_url" :page="props.urlParts.url_parameter_2" />
  </div>
  <div v-else-if="widget.outer == 'Blog/article_view'">
    <page-builder-article-view :templatevar="widget.templatevar" :article="props.urlParts.item_url" />
  </div>
  <div v-else-if="widget.outer == 'Sell/product_grid'">
    <page-builder-tpl-shop :templatevar="widget.templatevar" :group="props.urlParts.item_url" :page="props.urlParts.url_parameter_2" />
  </div>
  <div v-else-if="widget.outer == 'Sell/product_view'">
    <page-builder-tpl-sell-product-view :templatevar="widget.templatevar" :product="props.urlParts.item_url" />
  </div>
  <div v-else-if="widget.outer == 'Ztt/home'">
    <page-builder-home :templatevar="widget.templatevar" />
  </div>
  <cgn-page-builder v-else :widget="widget" :url-parts="urlParts" :contained-class="props.containedClass" />
</template>

<script setup lang="ts">
import { CognitoUrlParts } from '~cognito/models/Cognito/Page'

const props = defineProps({
  widget: {
    required: true,
  },
  containedClass: {
    type: String,
    default: 'px-6 py-2 mx-auto max-w-5xl',
  },
  urlParts: {
    type: CognitoUrlParts,
    required: true,
  },
})
</script>
